import * as React from "react";
import { navigate } from "gatsby";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import img404 from "images/404.webp";
import "./404.scss";

// markup
const NotFoundPage = () => {
  return (
    <Layout pageClass="page-not-found">
      <Seo pageTitle="Page not found" pageDescription="Page is not found" />
      <div className="inner-body-content">
        <section className="inner-centered-container">
          <Row>
            <Col sm={12}>
              <div className="inner-content">
                <img src={img404} alt="Page not found" />
                <h1>Something's Missing</h1>
                <p>
                  The page you are looking for may have been removed or you
                  assembled the link incorrectly.
                </p>
                <div className="buttons-bar">
                  <button className="button" onClick={() => navigate("/")}>
                    HOME
                  </button>
                  <button className="button blue" onClick={() => navigate(-1)}>
                    PREVIOUS PAGE
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </section>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
